// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-afterschool-index-js": () => import("./../src/pages/afterschool/index.js" /* webpackChunkName: "component---src-pages-afterschool-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-bookaparty-index-js": () => import("./../src/pages/bookaparty/index.js" /* webpackChunkName: "component---src-pages-bookaparty-index-js" */),
  "component---src-pages-camp-index-js": () => import("./../src/pages/camp/index.js" /* webpackChunkName: "component---src-pages-camp-index-js" */),
  "component---src-pages-classes-index-js": () => import("./../src/pages/classes/index.js" /* webpackChunkName: "component---src-pages-classes-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-covid-19-index-js": () => import("./../src/pages/covid-19/index.js" /* webpackChunkName: "component---src-pages-covid-19-index-js" */),
  "component---src-pages-dailyworkout-index-js": () => import("./../src/pages/dailyworkout/index.js" /* webpackChunkName: "component---src-pages-dailyworkout-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-obstaclefitkids-index-js": () => import("./../src/pages/obstaclefitkids/index.js" /* webpackChunkName: "component---src-pages-obstaclefitkids-index-js" */),
  "component---src-pages-obstacles-index-js": () => import("./../src/pages/obstacles/index.js" /* webpackChunkName: "component---src-pages-obstacles-index-js" */),
  "component---src-pages-ofkathome-index-js": () => import("./../src/pages/ofkathome/index.js" /* webpackChunkName: "component---src-pages-ofkathome-index-js" */),
  "component---src-pages-ofkworkout-index-js": () => import("./../src/pages/ofkworkout/index.js" /* webpackChunkName: "component---src-pages-ofkworkout-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-rules-index-js": () => import("./../src/pages/rules/index.js" /* webpackChunkName: "component---src-pages-rules-index-js" */),
  "component---src-pages-schedule-index-js": () => import("./../src/pages/schedule/index.js" /* webpackChunkName: "component---src-pages-schedule-index-js" */),
  "component---src-pages-team-index-js": () => import("./../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-termsofuse-index-js": () => import("./../src/pages/termsofuse/index.js" /* webpackChunkName: "component---src-pages-termsofuse-index-js" */),
  "component---src-pages-waiver-index-js": () => import("./../src/pages/waiver/index.js" /* webpackChunkName: "component---src-pages-waiver-index-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-daily-workout-js": () => import("./../src/templates/daily-workout.js" /* webpackChunkName: "component---src-templates-daily-workout-js" */),
  "component---src-templates-ofk-workout-js": () => import("./../src/templates/ofk-workout.js" /* webpackChunkName: "component---src-templates-ofk-workout-js" */)
}

